/**
 * Type: ページ
 * What: 団体利用LP
 */
import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import BreadcrumbComponent from '../../components/Breadcrumb';
import Image from '../../util/Image';
import { Button } from '../../components/Btn';

const pageTitle = 'イベントホール（貸しホール）施設概要';
const pageDescription = '6Fイベントホールは、多目的にご利用いただける貸ホールです。最先端のアートからサブカルチャーまで様々な展示会、イベント等でご利用いただけます。';
const pageSlug = 'eventhall-overview';
// Data Props Template
const ticketHowPage = () => (
  <Layout pageSlug={pageSlug}>
    <SEO title={pageTitle} description={pageDescription} />
    <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
    <section className="section">
      <div className="container">
        <h3 className="headline pc-mb60 sp-mb30">
          <span>施設概要</span>
        </h3>
        <div className="inner trans">
          <div className="slime-table">
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>名称</th>
                    <td className="is-size-6">
                      BOSS E・ZO FUKUOKA<br className="sp" />（ボス イーゾ フクオカ）
                    </td>
                  </tr>
                  <tr>
                    <th>所在地</th>
                    <td className="is-size-6">
                     福岡市中央区地行浜2-2-6
                    </td>
                  </tr>
                  <tr>
                    <th>
                      ホール
                    </th>
                    <td className="is-size-6">
                      <dl className="base-dl ml-10">
                        <dt>【A テナント1】</dt>
                        <dd><b>面積 303.7㎡ / 天井高 4.0m</b></dd>
                      </dl>
                      <dl className="base-dl ml-10">
                        <dt>【B テナント2】</dt>
                        <dd><b>面積 322.6㎡ / 天井高 4.0m</b></dd>
                      </dl>
                      <dl className="base-dl ml-10">
                        <dt>【C テナント3】</dt>
                        <dd><b>面積 53.0㎡ / 天井高 4.0m</b></dd>
                      </dl>
                      <dl className="base-dl ml-10">
                        <dt>【D 客用通路】</dt>
                        <dd><b>面積 69.1㎡ / 天井高 4.0m</b></dd>
                      </dl>
                      <dl className="base-dl ml-10">
                        <dt>【E バックヤード】</dt>
                        <dd><b>面積 71.2㎡ / 天井高 3.0m</b></dd>
                      </dl>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container mb30">
        <h4 className="headline pc-mb60 sp-mb30">
          <span>平面図・写真</span>
        </h4>
        <div className="inner-slim trans">
          <div className="col-image">
            <figure className="image">
              <Image filename="img-eventhal-overviews-1.jpg" />
            </figure>
          </div>
        </div>
        <div className="inner">
          <div className="sc-columns mb30">
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-overviews-2.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                   Aテナント1
                  </h5>
                </div>
              </div>
            </div>
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-overviews-3.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                   Bテナント2
                  </h5>
                </div>
              </div>
            </div>
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-overviews-4.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                   Cテナント3
                  </h5>
                </div>
              </div>
            </div>
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-overviews-6.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                   D客用通路
                  </h5>
                </div>
              </div>
            </div>
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-overviews-5.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                   Eバックヤード
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <ul className='list-base'>
            <li>展示スペースとして最大748.4㎡の利用可能</li>
            <li>スポットライト等設置可能な配線ダクトレール配置</li>
            <li>バックヤードにはトイレ/SKを完備</li>
          </ul>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h4 className="headline pc-mb60 sp-mb30 tag-point-wrap tags">
          <span className="tag point">POINT</span>
          <span>搬出入について</span>
        </h4>
        <div className="inner">
          <div className="sc-columns mb30">
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-overviews-6.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                   6F搬出入スペース
                  </h5>
                </div>
              </div>
            </div>
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-overviews-7.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                    車両・荷物用リフター前
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="sc-columns sp-mb10">
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-overviews-8.jpg" />
                  </figure>
                </div>
                <div className="col-content col-credit-wrap">
                  <h5 className="schedule-title">
                    車両・荷物用リフター
                  </h5>
                </div>
              </div>
            </div>
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-overviews-9.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                    車両・荷物用リフター
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <ul className='list-base'>
            <li>天井高3.0m</li>
            <li>2tトラックをそのまま載せることが出来る</li>
            <li>車両荷物用リフターで6Fへ搬入可能</li>
          </ul>
          <div className="slime-table">
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>車両荷物用リフター</th>
                    <td className="is-size-6">
                      <dl className="base-dl ml-4">
                        <dt>積載重量</dt>
                        <dd><b>5000kg</b></dd>
                      </dl>
                      <dl className="base-dl ml-4">
                        <dt>屋内側</dt>
                        <dd><b>（間口×奥行×高さ）3.5m×5.8m×3.0m</b></dd>
                      </dl>
                      <dl className="base-dl ml-4">
                        <dt>屋外側</dt>
                        <dd><b>（間口×高さ）3.5m×3.0m</b></dd>
                      </dl>
                    </td>
                  </tr>
                  <tr>
                    <th>業務用エレベーター</th>
                    <td className="is-size-6">
                      <dl className="base-dl ml-4">
                        <dt>積載重量</dt>
                        <dd><b>1150kg</b></dd>
                      </dl>
                      <dl className="base-dl ml-4">
                        <dt>サイズ</dt>
                        <dd><b>（間口×奥行×高さ）1.1m×1.6m×2.1m</b></dd>
                      </dl>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <div className="inner">
          <div
            className="btn-wrap mt-5"
          >
            <Button href="/access" className="btn-regular wide">
              アクセス情報
            </Button>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ticketHowPage;
